export const TwainLogo = (props: React.ComponentProps<"svg">): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="113"
    height="40"
    fill="none"
    viewBox="0 0 113 40"
    {...props}
  >
    <path
      fill="#22262A"
      stroke="#22262A"
      d="M11.2.5h17.6c1.9684 0 3.4147.00039 4.5569.0937 1.1363.09285 1.9276.27518 2.594.61476a6.49933 6.49933 0 0 1 2.8406 2.8406c.3396.66646.5219 1.45773.6148 2.59407.0933 1.14218.0937 2.58844.0937 4.55687v17.6c0 1.9684-.0004 3.4147-.0937 4.5569-.0929 1.1363-.2752 1.9276-.6148 2.594a6.49871 6.49871 0 0 1-2.8406 2.8406c-.6664.3396-1.4577.5219-2.594.6148-1.1422.0933-2.5885.0937-4.5569.0937H11.2c-1.96843 0-3.41469-.0004-4.55687-.0937-1.13634-.0929-1.92761-.2752-2.59407-.6148a6.49933 6.49933 0 0 1-2.8406-2.8406c-.33958-.6664-.5219-1.4577-.61475-2.594C.50039 32.2147.5 30.7684.5 28.8V11.2c0-1.96843.00039-3.41469.0937-4.55687.09285-1.13634.27518-1.92761.61476-2.59407a6.49995 6.49995 0 0 1 2.8406-2.8406C4.71552.86888 5.50679.68656 6.64313.59371 7.78531.50039 9.23157.5 11.2.5Z"
    />
    <path
      fill="#fff"
      fillOpacity=".1"
      fillRule="evenodd"
      d="M.76295 3.82207C0 5.31945 0 7.27963 0 11.2v17.6c0 3.9204 0 5.8805.76295 7.3779a6.99956 6.99956 0 0 0 3.05912 3.0591C5.31945 40 7.27963 40 11.2 40h17.8507H12.64c-3.72435 0-5.58652 0-7.00904-.7439-1.25127-.6543-2.2686-1.6984-2.90615-2.9826C2 34.8135 2 32.9024 2 29.08V9.8009c0-3.08093 0-4.6214.59947-5.79791A5.49998 5.49998 0 0 1 5.00308 1.5994C6.1796.99994 7.71984.99995 10.8003.99997L17.64 1h11.72c3.7244 0 5.5865 0 7.009.74388 1.2513.65434 2.2686 1.69843 2.9062 2.98264C40 6.18646 40 8.09764 40 11.92v-.72c0-3.92037 0-5.88055-.763-7.37793A6.99956 6.99956 0 0 0 36.1779.76295C34.6805 0 32.7204 0 28.8 0H11.2C7.27963 0 5.31945 0 3.82207.76295A6.99999 6.99999 0 0 0 .76295 3.82207Z"
      clipRule="evenodd"
    />
    <path
      fill="#16181D"
      fillRule="evenodd"
      d="M0 28.08v.72c0 3.9204 0 5.8805.76295 7.3779a6.99956 6.99956 0 0 0 3.05912 3.0591C5.31945 40 7.27963 40 11.2 40h17.6c3.9204 0 5.8805 0 7.3779-.763a6.99913 6.99913 0 0 0 3.0591-3.0591C40 34.6805 40 32.7204 40 28.8V11.2c0-3.92037 0-5.88055-.763-7.37793A6.99956 6.99956 0 0 0 36.1779.76295C35.0461.18626 33.6499.04547 31.3426.0111c1.727.03086 2.7891.1475 3.6544.58837a5.50026 5.50026 0 0 1 2.4035 2.40359C38 4.17957 38 5.71978 38 8.80021V30.1991c0 3.0809 0 4.6213-.5995 5.7978a5.49993 5.49993 0 0 1-2.4036 2.4036C33.8204 39 32.28 39 29.1991 39H10.64c-3.72435 0-5.58652 0-7.00904-.7439-1.25128-.6543-2.2686-1.6984-2.90615-2.9826C0 33.8135 0 31.9024 0 28.08Z"
      clipRule="evenodd"
    />
    <path
      fill="url(#a)"
      fillOpacity=".12"
      d="M0 11.2c0-3.92037 0-5.88055.76295-7.37793A6.99999 6.99999 0 0 1 3.82207.76295C5.31945 0 7.27963 0 11.2 0h17.6c3.9204 0 5.8806 0 7.3779.76295a6.99956 6.99956 0 0 1 3.0591 3.05912C40 5.31945 40 7.27963 40 11.2v17.6c0 3.9204 0 5.8806-.763 7.3779a6.99913 6.99913 0 0 1-3.0591 3.0591C34.6806 40 32.7204 40 28.8 40H11.2c-3.92037 0-5.88055 0-7.37793-.763a6.99956 6.99956 0 0 1-3.05912-3.0591C0 34.6806 0 32.7204 0 28.8V11.2Z"
    />
    <path fill="#7B90DF" d="M30 8h1v24h-1V8Z" />
    <path fill="#22262A" d="M31 9h1v24h-1z" />
    <g filter="url(#b)" opacity=".32">
      <path
        fill="#7B90DF"
        fillRule="evenodd"
        d="M17 12h-7v4h2v-2h5v14h-2v2h6v-2h-2V14h5v2h2v-4h-9Z"
        clipRule="evenodd"
      />
    </g>
    <path
      fill="#22262A"
      fillRule="evenodd"
      d="M17 12h-7v4h2v-2h5v14h-2v2h6v-2h-2V14h5v2h2v-4h-9Z"
      clipRule="evenodd"
    />
    <path
      fill="#E1E4E4"
      fillRule="evenodd"
      d="M16 11H9v4h2v-2h5v14h-2v2h6v-2h-2V13h5v2h2v-4h-9Z"
      clipRule="evenodd"
    />
    <path
      fill="url(#c)"
      fillRule="evenodd"
      d="M16 11H9v4h2v-2h5v14h-2v2h6v-2h-2V13h5v2h2v-4h-9Z"
      clipRule="evenodd"
    />
    <path
      fill="#2C3235"
      fillRule="evenodd"
      d="M95.5 11.75c0-.9665.7835-1.75 1.75-1.75s1.75.7835 1.75 1.75-.7835 1.75-1.75 1.75-1.75-.7835-1.75-1.75ZM57.5 29H60V13.5h5.5V11H52v2.5h5.5V29ZM69 26.5 72 16h2l3 10.5L79.5 16H82l-3.5 13h-3L73 19.5 70.5 29h-3L64 16h2.5L69 26.5ZM98.5 29V16H96v13h2.5ZM91 26.5044V28.5h2.5v-8.225c0-1.73-.6073-2.8434-1.4749-3.5323-.8783-.6974-2.1193-.9927-3.5539-.9927-1.4302 0-2.7121.3123-3.6601.9738-.8797.6139-1.4904 1.5762-1.662 2.7762H85.58c.1048-.5951.3499-1.0189.7694-1.3775.4688-.4009 1.1215-.5975 1.9468-.5975.9247 0 1.605.2283 2.0436.7443.4304.5064.6602 1.2374.6602 2.1307v.6h-1.9538c-1.8579 0-3.484.4228-4.6368 1.0499-1.1348.6174-1.9094 1.7515-1.9094 3.1751 0 1.2956.5621 2.1882 1.2888 2.7633.7357.5822 1.768 1.0117 2.9324 1.0117.9692 0 1.7169-.3255 2.3194-.6267.6021-.3011 1.0739-.7351 1.4811-1.274L91 26.5044ZM91 22.5V24c0 .9133-.4571 1.749-1.0928 2.25-.6296.4961-1.4949 1.05-2.461 1.05-.7866 0-1.3836-.2517-1.7842-.63-.4029-.3806-.662-.9759-.662-1.57 0-.4397.0965-.8207.3-1.1411.2031-.3197.508-.6353 1.05-.9089.6049-.3053 1.65-.55 2.7712-.55H91Zm10 6.4999h2.5v-7.7c0-2.075 1.9-3.2999 3.6-3.2999 2.1 0 2.9.9 2.9 3.0499v7.95h2.5v-8.225c0-3.6-1.4-5.0249-4.3-5.0249-2.15 0-4.075.9749-4.7 2.2499V16H101v12.9999Z"
      clipRule="evenodd"
    />
    <defs>
      <linearGradient
        id="a"
        x1="20"
        x2="20"
        y1="0"
        y2="40"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset="1" stopColor="#fff" stopOpacity=".25" />
      </linearGradient>
      <linearGradient
        id="c"
        x1="17"
        x2="17"
        y1="11"
        y2="29"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" stopOpacity=".5" />
        <stop offset="1" stopColor="#E9ECEC" stopOpacity="0" />
        <stop offset="1" stopColor="#fff" stopOpacity=".2" />
      </linearGradient>
      <filter
        id="b"
        width="36"
        height="38"
        x="0"
        y="2"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur
          result="effect1_foregroundBlur_553_2835"
          stdDeviation="5"
        />
      </filter>
    </defs>
  </svg>
);
