export const FMGLogo = (props: React.ComponentProps<"svg">): JSX.Element => (
  <svg
    fill="none"
    height="65"
    viewBox="0 0 99 65"
    width="99"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M91.6537 48.8848C91.6286 51.2014 90.6873 53.4147 89.034 55.044C87.3807 56.6734 85.1491 57.5873 82.8235 57.5873C80.4979 57.5873 78.2662 56.6734 76.613 55.044C74.9597 53.4147 74.0183 51.2014 73.9933 48.8848H66.647C66.681 53.1366 68.4003 57.2027 71.4302 60.1973C74.4602 63.1919 78.5554 64.8723 82.8235 64.8723C87.0916 64.8723 91.1867 63.1919 94.2167 60.1973C97.2467 57.2027 98.966 53.1366 99 48.8848H91.6537Z"
      fill="currentColor"
    ></path>{" "}
    <path
      d="M50.0289 14.0059C46.79 13.9988 43.6748 15.2445 41.3398 17.4807C39.005 15.2443 35.8896 13.9985 32.6506 14.0059C29.3986 13.9754 26.2672 15.2309 23.9431 17.4972C21.619 19.7635 20.2919 22.8555 20.2529 26.0951V45.144H27.671V26.0951C27.7395 24.8255 28.294 23.6303 29.2203 22.7558C30.1467 21.8812 31.3744 21.3937 32.6506 21.3937C33.9269 21.3937 35.1546 21.8812 36.0809 22.7558C37.0073 23.6303 37.5618 24.8255 37.6302 26.0951V45.144H45.0494V26.0951C45.1178 24.8255 45.6723 23.6303 46.5987 22.7558C47.525 21.8812 48.7527 21.3937 50.0289 21.3937C51.3052 21.3937 52.5329 21.8812 53.4592 22.7558C54.3856 23.6303 54.9401 24.8255 55.0085 26.0951V45.144H62.4277V26.0951C62.3884 22.8554 61.0612 19.7634 58.7369 17.4971C56.4126 15.2309 53.2811 13.9754 50.0289 14.0059Z"
      fill="currentColor"
    ></path>{" "}
    <path
      d="M91.2221 14.6449V17.67C90.1195 16.4662 88.772 15.5103 87.2695 14.866C85.767 14.2217 84.1438 13.9039 82.5083 13.9335C80.4092 13.9391 78.332 14.3586 76.3964 15.168C74.4609 15.9773 72.7054 17.1604 71.2311 18.649C69.7569 20.1376 68.5931 21.9023 67.8069 23.8413C67.0207 25.7802 66.6276 27.8551 66.6503 29.9461C66.6503 29.9635 66.6503 29.9799 66.6503 29.9962C66.6503 30.0126 66.6503 30.031 66.6503 30.0494C66.664 34.323 68.3811 38.4162 71.4241 41.4286C74.4672 44.4409 78.5867 46.1256 82.8766 46.112C87.1665 46.0985 91.2752 44.3878 94.299 41.3563C97.3228 38.3248 99.0139 34.2208 99.0003 29.9472V14.6449H91.2221ZM82.8243 38.7424C81.0899 38.7338 79.397 38.2134 77.9591 37.2472C76.5213 36.2809 75.403 34.9119 74.7454 33.3131C74.0878 31.7142 73.9204 29.9572 74.2642 28.2636C74.6081 26.5701 75.4478 25.0159 76.6774 23.7974C77.9071 22.5788 79.4715 21.7504 81.1732 21.4168C82.875 21.0831 84.6378 21.2591 86.2393 21.9226C87.8407 22.5861 89.209 23.7073 90.1714 25.1448C91.1338 26.5822 91.6472 28.2714 91.6468 29.9993C91.633 32.3218 90.6976 34.5447 89.0447 36.1827C87.3918 37.8207 85.1557 38.7409 82.8243 38.7424Z"
      fill="currentColor"
    ></path>{" "}
    <path
      d="M14.3428 7.6677C15.0026 7.66806 15.659 7.7627 16.2919 7.94875V0.130499C15.6458 0.0440932 14.9947 0.00073168 14.3428 0.00070463C10.5824 -0.0366594 6.96061 1.41327 4.27184 4.03247C1.58307 6.65168 0.0468066 10.2263 0 13.9724V45.2914H7.69406V24.9476H16.2878V17.5575H7.69714V13.9724C7.7423 12.2594 8.46704 10.6341 9.71264 9.45245C10.9582 8.27075 12.6232 7.62899 14.3428 7.6677Z"
      fill="currentColor"
    ></path>
  </svg>
);
