export const TwainLightLogo = (
  props: React.ComponentProps<"svg">,
): JSX.Element => (
  <svg
    width="113"
    height="40"
    viewBox="0 0 113 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.2 0.5H28.8C30.7684 0.5 32.2147 0.500389 33.3569 0.593709C34.4932 0.686552 35.2845 0.868881 35.9509 1.20846C37.174 1.83163 38.1684 2.82601 38.7915 4.04906C39.1311 4.71552 39.3134 5.50679 39.4063 6.64313C39.4996 7.78531 39.5 9.23157 39.5 11.2V28.8C39.5 30.7684 39.4996 32.2147 39.4063 33.3569C39.3134 34.4932 39.1311 35.2845 38.7915 35.9509C38.1684 37.174 37.174 38.1684 35.9509 38.7915C35.2845 39.1311 34.4932 39.3134 33.3569 39.4063C32.2147 39.4996 30.7684 39.5 28.8 39.5H11.2C9.23157 39.5 7.78531 39.4996 6.64313 39.4063C5.50679 39.3134 4.71552 39.1311 4.04906 38.7915C2.82601 38.1684 1.83163 37.174 1.20846 35.9509C0.868881 35.2845 0.686552 34.4932 0.593709 33.3569C0.500389 32.2147 0.5 30.7684 0.5 28.8V11.2C0.5 9.23157 0.500389 7.78531 0.593709 6.64313C0.686552 5.50679 0.868881 4.71552 1.20846 4.04906C1.83163 2.82601 2.82601 1.83163 4.04906 1.20846C4.71552 0.868881 5.50679 0.686552 6.64313 0.593709C7.78531 0.500389 9.23157 0.5 11.2 0.5Z"
      fill="#E1E4E4"
      stroke="#E1E4E4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.762954 3.82207C0 5.31945 0 7.27963 0 11.2V28.8C0 32.7204 0 34.6805 0.762954 36.1779C1.43407 37.4951 2.50493 38.5659 3.82207 39.237C5.31945 40 7.27963 40 11.2 40H28.8C28.8845 40 28.968 40 29.0507 40H12.64C8.91565 40 7.05348 40 5.63096 39.2561C4.37969 38.6018 3.36236 37.5577 2.72481 36.2735C2 34.8135 2 32.9024 2 29.08V11.92V9.8009C2 6.71997 2 5.1795 2.59947 4.00299C3.12678 2.96809 3.96818 2.1267 5.00308 1.5994C6.1796 0.999935 7.71984 0.999946 10.8003 0.999967L17.64 1H29.36C33.0844 1 34.9465 1 36.369 1.74388C37.6203 2.39822 38.6376 3.44231 39.2752 4.72652C40 6.18646 40 8.09764 40 11.92L40 11.2C40 7.27963 40 5.31945 39.237 3.82207C38.5659 2.50493 37.4951 1.43407 36.1779 0.762954C34.6805 0 32.7204 0 28.8 0H11.2C7.27963 0 5.31945 0 3.82207 0.762954C2.50493 1.43407 1.43407 2.50493 0.762954 3.82207Z"
      fill="white"
      fillOpacity="0.32"
    />
    <path
      opacity="0.05"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 28.08V28.8C0 32.7204 0 34.6806 0.762954 36.1779C1.43407 37.4951 2.50493 38.5659 3.82207 39.2371C5.31945 40 7.27963 40 11.2 40H28.8C32.7204 40 34.6805 40 36.1779 39.2371C37.4951 38.5659 38.5659 37.4951 39.237 36.1779C40 34.6806 40 32.7204 40 28.8V11.2C40 7.27964 40 5.31946 39.237 3.82207C38.5659 2.50494 37.4951 1.43407 36.1779 0.762961C35.0461 0.18627 33.6499 0.04548 31.3426 0.0111084C33.0696 0.041965 34.1317 0.158599 34.997 0.599476C36.0318 1.12678 36.8732 1.96817 37.4005 3.00306C38 4.17958 38 5.71979 38 8.80021V15.92V28.08V30.1991C38 33.28 38 34.8204 37.4005 35.997C36.8732 37.0318 36.0318 37.8732 34.9969 38.4005C33.8204 39 32.28 39 29.1991 39H27.36H10.64C6.91565 39 5.05348 39 3.63096 38.2561C2.37968 37.6018 1.36236 36.5577 0.724807 35.2735C0 33.8135 0 31.9024 0 28.08Z"
      fill="#16181D"
    />
    <path
      d="M0 11.2C0 7.27963 0 5.31945 0.762954 3.82207C1.43407 2.50493 2.50493 1.43407 3.82207 0.762954C5.31945 0 7.27963 0 11.2 0H28.8C32.7204 0 34.6806 0 36.1779 0.762954C37.4951 1.43407 38.5659 2.50493 39.237 3.82207C40 5.31945 40 7.27963 40 11.2V28.8C40 32.7204 40 34.6806 39.237 36.1779C38.5659 37.4951 37.4951 38.5659 36.1779 39.237C34.6806 40 32.7204 40 28.8 40H11.2C7.27963 40 5.31945 40 3.82207 39.237C2.50493 38.5659 1.43407 37.4951 0.762954 36.1779C0 34.6806 0 32.7204 0 28.8V11.2Z"
      fill="url(#paint0_linear_4421_54855)"
      fillOpacity="0.16"
    />
    <path d="M30 8H31V32H30V8Z" fill="#557BD8" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 11H9V13V15H11V13H16V27H14V29H20V27H18V13H23V15H25V13V11H18H16Z"
      fill="#16181D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 11H9V13V15H11V13H16V27H14V29H20V27H18V13H23V15H25V13V11H18H16Z"
      fill="url(#paint1_linear_4421_54855)"
      fillOpacity="0.24"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M95.5 11.75C95.5 10.7835 96.2835 10 97.25 10C98.2165 10 99 10.7835 99 11.75C99 12.7165 98.2165 13.5 97.25 13.5C96.2835 13.5 95.5 12.7165 95.5 11.75ZM57.5 29H60V13.5H65.5V11H52V13.5H57.5V29ZM69 26.5L72 16H74L77 26.5L79.5 16H82L78.5 29H75.5L73 19.5L70.5 29H67.5L64 16H66.5L69 26.5ZM98.5 29V16H96V29H98.5ZM91 26.5044V28.5H93.5V20.275C93.5 18.545 92.8927 17.4316 92.0251 16.7427C91.1468 16.0453 89.9058 15.75 88.4712 15.75C87.041 15.75 85.7591 16.0623 84.8111 16.7238C83.9314 17.3377 83.3207 18.3 83.1491 19.5H85.58C85.6848 18.9049 85.9299 18.4811 86.3494 18.1225C86.8182 17.7216 87.4709 17.525 88.2962 17.525C89.2209 17.525 89.9012 17.7533 90.3398 18.2693C90.7702 18.7757 91 19.5067 91 20.4V21H89.0462C87.1883 21 85.5622 21.4228 84.4094 22.0499C83.2746 22.6673 82.5 23.8014 82.5 25.225C82.5 26.5206 83.0621 27.4132 83.7888 27.9883C84.5245 28.5705 85.5568 29 86.7212 29C87.6904 29 88.4381 28.6745 89.0406 28.3733C89.6427 28.0722 90.1145 27.6382 90.5217 27.0993L91 26.5044ZM91 22.5V24C91 24.9133 90.5429 25.749 89.9072 26.25C89.2776 26.7461 88.4123 27.3 87.4462 27.3C86.6596 27.3 86.0626 27.0483 85.662 26.67C85.2591 26.2894 85 25.6941 85 25.1C85 24.6603 85.0965 24.2793 85.3 23.9589C85.5031 23.6392 85.808 23.3236 86.35 23.05C86.9549 22.7447 88 22.5 89.1212 22.5H91ZM101 28.9999H103.5V21.2999C103.5 19.2249 105.4 18 107.1 18C109.2 18 110 18.9 110 21.0499V28.9999H112.5V20.7749C112.5 17.1749 111.1 15.75 108.2 15.75C106.05 15.75 104.125 16.7249 103.5 17.9999V16H101V28.9999Z"
      fill="#D7DADC"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4421_54855"
        x1="20"
        y1="0"
        x2="20"
        y2="40"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4421_54855"
        x1="17"
        y1="11"
        x2="17"
        y2="29"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.5" />
        <stop offset="1" stopColor="#E9ECEC" stopOpacity="0" />
        <stop offset="1" stopColor="white" stopOpacity="0.2" />
      </linearGradient>
    </defs>
  </svg>
);
