export const SimpleClubLogo = (
  props: React.ComponentProps<"svg">,
): JSX.Element => (
  <svg
    {...props}
    viewBox="0 0 160 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M60.5881 10.1591C60.3232 9.91391 60.0117 9.72445 59.6721 9.60199C59.3326 9.47954 58.9719 9.42659 58.6114 9.44629C57.6719 9.44629 57.1534 9.73798 57.1534 10.2401C57.1534 10.7586 57.6232 11.0503 58.644 11.115C60.1507 11.2123 62.0625 11.5524 62.0625 13.6748C62.0625 15.0844 60.9122 16.2994 58.6277 16.2994C57.9452 16.3466 57.2607 16.2434 56.6224 15.9971C55.9841 15.7507 55.4077 15.3673 54.9339 14.8738L55.9059 13.4642C56.6657 14.1463 57.6398 14.5416 58.6601 14.5821C59.4055 14.5983 60.102 14.2096 60.102 13.6262C60.102 13.0754 59.6483 12.8486 58.5143 12.7837C57.0077 12.6703 55.2093 12.1195 55.2093 10.3048C55.2093 8.45805 57.121 7.80994 58.5791 7.80994C59.1413 7.77255 59.7054 7.84631 60.2392 8.02702C60.7729 8.20773 61.2658 8.49184 61.6897 8.86312L60.5881 10.1591Z"
      fill="black"
    />
    <path
      d="M65.084 5.78483C65.0807 6.09204 64.9563 6.38555 64.7379 6.60161C64.5195 6.81768 64.2247 6.93888 63.9174 6.93888C63.6102 6.93888 63.3154 6.81768 63.097 6.60161C62.8785 6.38555 62.7542 6.09204 62.7509 5.78483C62.7542 5.47762 62.8785 5.18411 63.097 4.96805C63.3154 4.75198 63.6102 4.63078 63.9174 4.63078C64.2247 4.63078 64.5195 4.75198 64.7379 4.96805C64.9563 5.18411 65.0807 5.47762 65.084 5.78483ZM62.9292 8.00433V16.024H64.9057V8.00433H62.9292Z"
      fill="black"
    />
    <path
      d="M71.5153 16.024V11.7468C71.5153 10.6939 70.9645 9.738 69.8792 9.738C68.8097 9.738 68.1779 10.6939 68.1779 11.7468V16.024H66.2014V8.02061H68.0321L68.1778 8.99254C68.3965 8.63565 68.7078 8.34473 69.0787 8.15074C69.4496 7.95676 69.8661 7.86698 70.284 7.89095C70.7848 7.86012 71.2826 7.98744 71.707 8.25492C72.1315 8.5224 72.4612 8.91652 72.6495 9.38157C72.9153 8.91395 73.3064 8.52975 73.7786 8.27215C74.2508 8.01454 74.7856 7.89375 75.3227 7.92333C77.5908 7.92333 78.7087 9.31663 78.7087 11.7144V16.024H76.7323V11.7144C76.7323 10.6613 76.2949 9.77034 75.2254 9.77034C74.1561 9.77034 73.492 10.6939 73.492 11.7468V16.024L71.5153 16.024Z"
      fill="black"
    />
    <path
      d="M80.0045 19.3614V8.03668H81.8513L81.9809 9.13853C82.2807 8.7351 82.6721 8.40867 83.1228 8.18614C83.5735 7.96361 84.0706 7.85135 84.5732 7.8586C85.1224 7.84372 85.6688 7.94229 86.1782 8.14815C86.6876 8.35401 87.1491 8.66274 87.5338 9.05503C87.9185 9.44732 88.2181 9.91475 88.414 10.4281C88.6098 10.9414 88.6977 11.4897 88.672 12.0385C88.672 14.3714 87.1977 16.2184 84.6381 16.2184C83.7956 16.2184 82.548 15.9593 81.9809 15.0844V19.3614H80.0045ZM86.6956 12.0385C86.6961 11.7403 86.6377 11.445 86.5239 11.1695C86.4102 10.8939 86.2432 10.6434 86.0325 10.4324C85.8218 10.2215 85.5716 10.0541 85.2962 9.93988C85.0208 9.82568 84.7256 9.7669 84.4274 9.7669C84.1293 9.7669 83.8341 9.82568 83.5587 9.93988C83.2833 10.0541 83.0331 10.2215 82.8224 10.4324C82.6117 10.6434 82.4447 10.8939 82.331 11.1695C82.2172 11.445 82.1588 11.7403 82.1593 12.0385C82.1588 12.3366 82.2172 12.632 82.331 12.9075C82.4447 13.1831 82.6117 13.4336 82.8224 13.6445C83.0331 13.8555 83.2833 14.0229 83.5587 14.1371C83.8341 14.2513 84.1293 14.3101 84.4274 14.3101C84.7256 14.3101 85.0208 14.2513 85.2962 14.1371C85.5716 14.0229 85.8218 13.8555 86.0325 13.6445C86.2432 13.4336 86.4102 13.1831 86.5239 12.9075C86.6377 12.632 86.6961 12.3366 86.6956 12.0385Z"
      fill="black"
    />
    <path d="M91.5063 4.69929V16.024H89.5459V4.69929H91.5063Z" fill="black" />
    <path
      d="M94.4116 12.735C94.541 13.7235 95.3996 14.4363 96.7931 14.4363C97.5716 14.4483 98.3293 14.1842 98.9316 13.6909L100.195 14.9384C99.2613 15.7988 98.03 16.2634 96.7605 16.2347C94.0065 16.2347 92.3699 14.5334 92.3699 11.9738C92.3699 9.54337 94.0228 7.79363 96.6148 7.79363C99.2882 7.79363 100.957 9.44627 100.649 12.735L94.4116 12.735ZM98.7533 11.0989C98.6238 10.0621 97.8137 9.54337 96.6795 9.54337C96.1846 9.50506 95.6917 9.63899 95.2842 9.92248C94.8768 10.206 94.5798 10.6216 94.4437 11.0989H98.7533Z"
      fill="black"
    />
    <path
      d="M108.872 15.0034C108.476 15.417 107.997 15.7431 107.467 15.9607C106.937 16.1783 106.367 16.2827 105.794 16.267C103.477 16.267 101.549 14.8738 101.549 12.0385C101.549 9.20324 103.477 7.80994 105.794 7.80994C106.331 7.79162 106.866 7.88757 107.363 8.09144C107.86 8.29532 108.308 8.60247 108.678 8.99256L107.43 10.3048C106.991 9.90502 106.42 9.68021 105.826 9.67303C105.516 9.6652 105.208 9.72155 104.921 9.83851C104.634 9.95548 104.375 10.1305 104.159 10.3527C103.943 10.5749 103.775 10.8393 103.666 11.1294C103.557 11.4195 103.509 11.729 103.526 12.0385C103.503 12.3451 103.546 12.653 103.651 12.9419C103.756 13.2308 103.921 13.4941 104.136 13.7145C104.35 13.9349 104.609 14.1074 104.895 14.2207C105.18 14.3339 105.487 14.3852 105.794 14.3714C106.115 14.3834 106.436 14.3309 106.736 14.2168C107.037 14.1027 107.311 13.9294 107.544 13.7072L108.872 15.0034Z"
      fill="black"
    />
    <path d="M111.696 4.69929V16.024H109.736V4.69929H111.696Z" fill="black" />
    <path
      d="M114.968 8.03668V12.2166C114.968 13.4318 115.632 14.3553 116.896 14.3553C117.178 14.3478 117.456 14.2835 117.714 14.166C117.971 14.0485 118.201 13.8804 118.392 13.6716C118.583 13.4629 118.729 13.2178 118.823 12.9512C118.917 12.6845 118.956 12.4016 118.937 12.1195V8.03668H120.898V16.0403H119.132L119.002 14.9548C118.663 15.3369 118.247 15.6418 117.78 15.8486C117.313 16.0555 116.807 16.1595 116.297 16.1537C114.385 16.1537 112.991 14.7117 112.991 12.2329V8.0367L114.968 8.03668Z"
      fill="black"
    />
    <path
      d="M124.17 4.69929V9.12223C124.64 8.29603 125.952 7.8423 126.794 7.8423C129.127 7.8423 130.861 9.26796 130.861 12.0222C130.861 14.647 129.095 16.2023 126.746 16.2023C126.242 16.2308 125.74 16.1278 125.288 15.9034C124.837 15.679 124.451 15.3409 124.17 14.9224L124.04 16.024H122.193V4.69929H124.17ZM124.299 12.0222C124.292 12.328 124.345 12.6322 124.457 12.9168C124.569 13.2015 124.737 13.461 124.951 13.6799C125.164 13.8988 125.419 14.0727 125.701 14.1915C125.983 14.3103 126.286 14.3715 126.592 14.3715C126.898 14.3715 127.2 14.3103 127.482 14.1915C127.764 14.0727 128.019 13.8988 128.233 13.6799C128.447 13.461 128.614 13.2015 128.726 12.9168C128.838 12.6322 128.892 12.328 128.884 12.0222C128.903 11.7166 128.857 11.4105 128.75 11.1236C128.643 10.8366 128.478 10.575 128.265 10.3555C128.051 10.136 127.794 9.96342 127.511 9.84871C127.227 9.73401 126.922 9.67973 126.616 9.68933C126.309 9.68419 126.004 9.74115 125.72 9.85678C125.435 9.9724 125.177 10.1443 124.961 10.3622C124.744 10.5801 124.574 10.8394 124.46 11.1247C124.347 11.4099 124.292 11.7152 124.299 12.0222Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.9872 7.174L39.0668 3.22339C38.7463 3.04044 38.353 3.04044 38.0326 3.22339L31.1121 7.17401C30.7869 7.35966 30.5862 7.7054 30.5862 8.07988V15.9201C30.5862 16.2946 30.7869 16.6403 31.1121 16.826L38.0326 20.7766C38.353 20.9596 38.7463 20.9596 39.0668 20.7766L45.9872 16.826C46.3125 16.6403 46.5132 16.2946 46.5132 15.9201V8.07988C46.5132 7.7054 46.3125 7.35966 45.9872 7.174ZM40.1011 1.41164C39.1396 0.862786 37.9597 0.862787 36.9983 1.41164L30.0779 5.36225C29.1022 5.91922 28.5 6.95644 28.5 8.07988V15.9201C28.5 17.0436 29.1022 18.0808 30.0779 18.6378L36.9983 22.5884C37.9597 23.1372 39.1396 23.1372 40.1011 22.5884L47.0215 18.6377C47.9972 18.0808 48.5994 17.0436 48.5994 15.9201V8.07988C48.5994 6.95644 47.9972 5.91922 47.0215 5.36225L40.1011 1.41164Z"
      fill="black"
    />
  </svg>
);
