export const GoMoonbeamLogo = (
  props: React.ComponentProps<"svg">,
): JSX.Element => (
  <svg
    {...props}
    viewBox="0 0 160 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M133.062 10.1269C133.179 9.95124 133.59 9.67807 133.943 9.52197C134.295 9.38539 134.981 9.30734 135.47 9.34636C136.116 9.4049 136.489 9.58051 137.37 10.5366L137.977 10.0098C138.329 9.69758 138.897 9.4049 139.23 9.34636C139.622 9.28783 140.17 9.34636 140.601 9.54149C141.051 9.73661 141.463 10.0878 141.639 10.439C141.874 10.8878 141.913 11.6488 141.835 16.5269L139.778 16.5854L139.759 14.4391C139.739 13.2683 139.681 12.0781 139.602 11.8049C139.511 11.4797 139.309 11.2976 138.995 11.2586C138.682 11.2195 138.349 11.3561 138.055 11.6488C137.82 11.9025 137.526 12.4488 137.429 12.8781C137.331 13.3073 137.233 14.322 137.233 16.5854L135.176 16.5269L135.079 14.0878C135.02 12.7415 134.883 11.5317 134.785 11.4147C134.667 11.2781 134.354 11.2391 134.041 11.2976C133.747 11.3561 133.355 11.6488 133.159 11.9415C132.924 12.3317 132.807 13.0342 132.631 16.5464H130.477V9.75612L131.495 9.69758C132.415 9.65856 132.533 9.69758 132.533 10.0488C132.533 10.2634 132.592 10.439 132.67 10.439C132.768 10.4586 132.924 10.3025 133.062 10.1269Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M123.901 9.34633C124.39 9.40487 125.017 9.6195 125.33 9.83413C125.624 10.0683 125.898 10.2439 125.957 10.2439C126.016 10.2439 126.055 10.1073 126.055 9.95121C126.055 9.71706 126.309 9.65852 128.209 9.65852L128.111 16.5463H127.034C126.192 16.5463 125.937 16.4683 125.898 16.2146C125.859 15.9415 125.781 15.9415 125.213 16.2927C124.802 16.5463 124.234 16.6829 123.509 16.6829C122.706 16.6829 122.256 16.5854 121.766 16.2537C121.394 16 120.905 15.5512 120.689 15.2585C120.415 14.8878 120.258 14.322 120.2 13.6C120.16 12.9756 120.219 12.1756 120.337 11.8049C120.454 11.4341 120.728 10.8683 120.924 10.5561C121.12 10.2439 121.668 9.83413 122.158 9.6195C122.745 9.36584 123.294 9.28779 123.901 9.34633ZM122.98 11.6683C122.726 11.9024 122.491 12.3122 122.432 12.5854C122.373 12.8585 122.412 13.3463 122.51 13.6585C122.608 13.9902 122.922 14.4 123.196 14.5951C123.49 14.7707 123.999 14.9268 124.332 14.9268C124.704 14.9268 125.174 14.7707 125.409 14.5366C125.781 14.2244 125.859 13.9317 125.859 13.0341C125.879 12.3512 125.781 11.8049 125.624 11.6683C125.487 11.5317 125.095 11.3561 124.762 11.2585C124.43 11.1805 123.979 11.1415 123.783 11.1805C123.568 11.2195 123.215 11.4537 122.98 11.6683Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M115.805 9.36589C116.353 9.42443 117.078 9.65858 117.469 9.91224C117.841 10.1659 118.35 10.7513 118.585 11.2391C118.84 11.7074 119.036 12.2342 119.036 12.3903C119.036 12.6049 118.311 12.9171 116.588 13.4635C115.237 13.8927 114.14 14.3025 114.14 14.4001C114.14 14.4781 114.375 14.6342 114.688 14.7513C114.982 14.8683 115.433 14.9074 115.707 14.8488C115.981 14.8098 116.333 14.7122 116.49 14.6342C116.686 14.5562 116.921 14.7318 117.234 15.2001C117.469 15.5903 117.665 15.9415 117.665 16.0001C117.665 16.0586 117.352 16.2342 116.98 16.3903C116.608 16.5464 115.805 16.683 115.217 16.683C114.63 16.683 113.846 16.5659 113.494 16.4098C113.161 16.2732 112.632 15.9025 112.338 15.5903C112.045 15.2781 111.712 14.7903 111.614 14.4976C111.496 14.1854 111.398 13.5805 111.398 13.1318C111.398 12.644 111.575 11.922 111.829 11.4342C112.084 10.9464 112.495 10.4 112.769 10.2049C113.043 10.0098 113.611 9.71711 114.042 9.56102C114.532 9.36589 115.178 9.30736 115.805 9.36589ZM113.572 12.5659C113.553 12.8 113.611 12.9757 113.709 12.9757C113.788 12.9757 114.434 12.7805 115.178 12.5269C115.903 12.2927 116.49 11.9805 116.49 11.8635C116.49 11.7269 116.294 11.4732 116.059 11.3171C115.805 11.161 115.413 11.0244 115.158 11.0244C114.904 11.0244 114.453 11.2781 114.14 11.5903C113.827 11.9025 113.572 12.3513 113.572 12.5659Z"
      fill="black"
    />
    <path
      d="M94.9399 10.4C95.0378 10.2049 95.4882 9.87319 95.9582 9.65856H95.9386C96.4086 9.44392 96.9765 9.26831 97.2115 9.26831C97.4661 9.26831 97.9556 9.38538 98.2886 9.52197C98.641 9.65856 99.0915 9.99026 99.2873 10.2634C99.5614 10.6537 99.6202 11.1805 99.6202 16.5854L97.564 16.5269L97.4661 14.0683C97.3877 12.1561 97.3094 11.5512 97.0744 11.4147C96.9177 11.3171 96.6044 11.2195 96.389 11.2195C96.1736 11.2195 95.8603 11.3171 95.7036 11.4537C95.5273 11.5903 95.2532 11.883 95.1161 12.0781C94.9203 12.3317 94.7832 13.1512 94.6265 16.5269L92.5703 16.5854V9.65856H93.5495C94.4503 9.65856 94.5286 9.69758 94.6461 10.2049C94.744 10.6537 94.7832 10.6927 94.9399 10.4Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M87.3984 9.38547C87.9467 9.444 88.5929 9.6001 88.8671 9.73669C89.1413 9.89278 89.5917 10.2635 89.905 10.6147C90.1987 10.9464 90.5316 11.5903 90.6491 12.0391C90.7666 12.5074 90.8058 13.2294 90.7471 13.6586C90.6687 14.0879 90.3946 14.7708 90.14 15.1611C89.8658 15.5708 89.2979 16.0782 88.8671 16.2928C88.3383 16.5465 87.6921 16.683 86.85 16.683C85.9492 16.683 85.4009 16.566 84.7547 16.2343C84.1868 15.9416 83.7168 15.4928 83.4426 15.005C83.1097 14.4586 82.9922 13.9318 82.9922 13.0733C83.0118 12.2147 83.1293 11.6879 83.4426 11.1611C83.6776 10.7513 84.1868 10.2245 84.5588 9.99035C84.9309 9.7562 85.5184 9.52205 85.8317 9.444C86.1451 9.34644 86.8696 9.32693 87.3984 9.38547ZM85.4401 12.0196C85.2834 12.3318 85.1463 12.8586 85.1463 13.1708C85.1463 13.5025 85.3617 14.0099 85.6359 14.3416C86.008 14.7904 86.2821 14.9269 86.8109 14.9269C87.3005 14.9269 87.6529 14.7708 87.9859 14.4391C88.3579 14.0879 88.4754 13.7367 88.4754 13.1123C88.495 12.5855 88.3579 12.0977 88.0838 11.7464C87.7704 11.3367 87.5354 11.2196 86.948 11.2391C86.5563 11.2391 86.1059 11.2977 85.9884 11.3562C85.8513 11.4147 85.5967 11.7074 85.4401 12.0196Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M78.1754 9.38551C78.7238 9.42454 79.5071 9.65868 79.9379 9.91234C80.3687 10.1465 80.8975 10.6733 81.152 11.1221C81.4458 11.6489 81.6025 12.2343 81.6025 12.9172C81.6025 13.4831 81.5045 14.1855 81.387 14.4782C81.2891 14.7904 80.9562 15.2782 80.6625 15.5904C80.3687 15.9026 79.84 16.2733 79.5071 16.4099C79.1546 16.566 78.3125 16.6831 77.6467 16.6831C76.7459 16.6831 76.2171 16.566 75.6296 16.2343C75.2184 16.0002 74.6505 15.4928 74.4155 15.1221C74.1609 14.7514 73.9063 14.0489 73.8476 13.5611C73.7693 13.0538 73.828 12.3514 73.9651 11.9026C74.1022 11.4733 74.533 10.8099 74.9247 10.4392C75.3163 10.0489 75.9821 9.63917 76.413 9.50259C76.8438 9.38551 77.6467 9.32698 78.1754 9.38551ZM76.0996 13.0928C76.1192 13.7367 76.2367 14.0685 76.6088 14.4392C76.9417 14.7709 77.3138 14.927 77.7838 14.927C78.3125 14.927 78.5867 14.7904 78.9588 14.3416C79.2329 14.0099 79.4483 13.5026 79.4483 13.1709C79.4483 12.8392 79.3308 12.3514 79.1742 12.0392C79.0371 11.7465 78.7629 11.4538 78.5867 11.3562C78.4104 11.2782 77.9796 11.2197 77.6075 11.2197C77.1179 11.2197 76.8046 11.3562 76.5109 11.727C76.1975 12.0977 76.08 12.488 76.0996 13.0928Z"
      fill="black"
    />
    <path
      d="M59.6875 16.5854V10.5366V4.48779L60.2358 5.26828C60.5492 5.69755 61.8808 7.57073 63.1733 9.40488C64.4853 11.2585 65.6016 12.761 65.6407 12.7415C65.6799 12.7024 67.0115 10.8878 68.5978 8.68293C70.184 6.47804 71.5352 4.64389 71.5744 4.58535C71.6136 4.52682 71.6331 7.19999 71.6331 16.5854H69.479V14.0098C69.479 12.4098 69.4007 11.4146 69.2832 11.4146C69.1657 11.4146 68.3236 12.5073 67.3836 13.8537C66.4241 15.2 65.6212 16.2927 65.5624 16.2927C65.5037 16.2927 64.7399 15.2976 63.8587 14.0488C62.9579 12.8195 62.155 11.8049 62.0375 11.8049C61.92 11.8049 61.822 12.761 61.7437 16.5268L59.6875 16.5854Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M101.984 4H104.139V6.88781C104.139 8.4683 104.178 9.81465 104.236 9.8927C104.295 9.99026 104.569 9.91221 104.883 9.75611C105.176 9.60001 105.803 9.4244 106.293 9.34635C106.88 9.2683 107.448 9.32684 107.957 9.52196C108.388 9.69757 108.976 10.0878 109.289 10.4195C109.583 10.7512 109.915 11.3951 110.033 11.8439C110.209 12.4683 110.209 12.9561 110.033 13.7561C109.915 14.3415 109.602 15.1025 109.348 15.4147C109.113 15.7269 108.584 16.1561 108.192 16.3317C107.781 16.5464 107.037 16.683 106.449 16.683C105.881 16.6634 105.196 16.5464 104.922 16.3903C104.648 16.2342 104.374 16.1171 104.295 16.0976C104.197 16.0976 104.139 16.2147 104.139 16.3317C104.139 16.5073 103.825 16.5854 102.082 16.5269L101.984 4ZM104.589 11.6878C104.236 12 104.139 12.3122 104.139 12.9756C104.139 13.6781 104.236 13.9708 104.648 14.3415C105.039 14.7317 105.372 14.8293 106.038 14.8293C106.763 14.8293 107.017 14.7317 107.389 14.3025C107.722 13.9317 107.859 13.522 107.859 12.9756C107.859 12.4488 107.722 12.039 107.428 11.7073C107.154 11.4147 106.704 11.1805 106.293 11.122C105.921 11.0829 105.49 11.1025 105.313 11.161C105.157 11.2195 104.824 11.4732 104.589 11.6878Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 4.58545H39.5205L40.8521 5.97082C41.5767 6.75131 42.6929 7.72692 43.3392 8.15619C43.9854 8.60497 45.2191 9.2879 46.0808 9.67814C46.9424 10.0879 48.5091 10.6733 51.4661 11.5123V12.3513C51.4661 13.0928 51.4074 13.1903 50.9374 13.2879C50.624 13.3464 49.7624 13.5806 49.0182 13.7952C48.2741 14.0099 46.9033 14.5367 45.9829 14.966C45.0625 15.3757 43.7896 16.1172 43.1433 16.566C42.4971 17.0343 41.4396 17.9904 39.6184 19.9806L17 20.0001V4.58545ZM25.2249 11.805H30.7081V6.34155H25.2249V11.805ZM32.4706 18.244H38.6392C40.9109 16.0391 42.2621 14.9855 43.1042 14.4782C43.9266 13.9708 44.612 13.4635 44.612 13.366C44.612 13.2489 43.5546 13.1708 41.8117 13.1708C39.1092 13.1708 39.0113 13.1903 38.5413 13.6782C38.228 13.9904 37.7972 14.1855 37.3663 14.205C36.9943 14.2245 36.4459 14.0879 36.1522 13.8928C35.8389 13.7172 35.5059 13.3269 35.3884 13.0343C35.271 12.7221 35.2318 12.1952 35.2905 11.8635C35.3689 11.4733 35.6234 11.0635 35.9564 10.8294C36.2697 10.5757 36.7788 10.4196 37.2293 10.4391C37.7123 10.4391 38.1497 10.6017 38.5413 10.9269C39.1092 11.3952 39.2267 11.4147 41.9683 11.4147C43.7308 11.4147 44.8079 11.3367 44.8079 11.2196C44.8079 11.1221 44.7295 11.0245 44.612 11.0245C44.4945 11.0245 43.6525 10.4977 42.6929 9.87327C41.7529 9.22936 40.4604 8.1757 39.8142 7.5318L38.6392 6.34155H32.4706V18.244ZM18.7625 18.244H24.2457V12.7806H18.7625V18.244Z"
      fill="black"
    />
  </svg>
);
