import { AmericanExpressLogo } from "@components/home/images/CompanyLogos/AmericanExpressLogo";
import { DuolingoLogo } from "@components/home/images/CompanyLogos/DuolingoLogo";
import { GoMoonbeamLogo } from "@components/home/images/CompanyLogos/GoMoonbeamLogo";
import { GustoLogo } from "@components/home/images/CompanyLogos/GustoLogo";
import { LightonLogo } from "@components/home/images/CompanyLogos/LightonLogo";
import { MemAILogo } from "@components/home/images/CompanyLogos/MemAILogo";
import { OvivaLogo } from "@components/home/images/CompanyLogos/OvivaLogo";
import { ProductNerdLogo } from "@components/home/images/CompanyLogos/ProductNerdLogo";
import { SimpleClubLogo } from "@components/home/images/CompanyLogos/SimpleClubLogo";

import { classNames } from "@components/library/utils/classNames";

interface TrustedByCompaniesProps {
  isDark?: boolean;
  overrideContainerClasses?: string;
  maxCols?: 4 | 5;
}

import React from "react";
import { AmexGBT } from "./images/CompanyLogos/AmexGBT";
import { TwainLogo } from "./images/CompanyLogos/TwainLogo";
import { DixaLogo } from "./images/CompanyLogos/DixaLogo";
import { FMGLogo } from "./images/CompanyLogos/FMGLogo";
import { TwainLightLogo } from "./images/CompanyLogos/TwainLightLogo";

export const TrustedByCompanies = ({
  isDark,
  overrideContainerClasses,
  maxCols,
}: TrustedByCompaniesProps) => {
  maxCols = maxCols ?? 5;
  return (
    <div
      className={classNames(
        "@container",
        isDark ? "bg-gray-950" : "",
        overrideContainerClasses ??
          "container-lg flex justify-center md:mx-auto",
      )}
    >
      <div
        className={classNames(
          "grid w-full grid-flow-col grid-rows-2 items-center justify-items-center gap-y-20",
          maxCols === 4 ? "" : "md:gap-x-[10px] md:gap-y-40",
        )}
      >
        {maxCols > 4 &&
          (isDark ? (
            <TwainLightLogo
              className={classNames(
                "flex h-full max-h-[30px] mx-auto w-[65%] md:w-full shrink-0 items-center justify-center",
              )}
            />
          ) : (
            <TwainLogo
              className={classNames(
                "flex h-full max-h-[30px] mx-auto w-[65%] md:w-full shrink-0 items-center justify-center",
              )}
            />
          ))}
        {maxCols > 4 && (
          <OvivaLogo
            className={classNames(
              "h-full w-full items-center justify-center",
              "max-h-[24px] w-full shrink-0",
              isDark && "invert",
            )}
          />
        )}
        <DuolingoLogo
          className={classNames(
            "flex h-full w-full items-center justify-center",
            "max-h-[28px] w-full shrink-0",
            isDark && "invert",
          )}
        />

        <DixaLogo
          className={classNames(
            "flex h-full mx-auto w-[75%] items-center justify-center max-h-[27px] @md:w-[80%] @lg:w-full shrink-0",
            isDark && "invert",
          )}
        />
        {/* <OptionalLink
          href={externalLinks && "https://www.amexglobalbusinesstravel.com"}
        >
          <AmexGBT
            className={classNames(
              "flex  opacity-90 h-full max-h-[36px] w-[75%] @md:w-[80%] mx-auto shrink items-center justify-center",
              isDark && "invert",
            )}
          />
        </OptionalLink> */}
        <FMGLogo
          className={classNames(
            "flex opacity-90 w-[75%] shrink items-center justify-center ",
            "h-full max-h-[20px] @lg:max-h-[28px]  mx-auto",
            isDark && "invert",
          )}
        />
        <SimpleClubLogo
          className={classNames(
            "flex h-full items-center justify-center",
            "max-h-[24px] w-full shrink-0",
            isDark && "invert",
          )}
        />
        <GustoLogo
          className={classNames(
            "flex h-full w-full items-center justify-center",
            "max-h-[26px] w-full shrink-0",
            isDark && "invert",
          )}
        />
        {maxCols === 4 ? (
          <OvivaLogo
            className={classNames(
              "h-full w-full items-center justify-center",
              "max-h-[26px] w-full shrink-0",
              isDark && "invert",
            )}
          />
        ) : (
          <GoMoonbeamLogo
            className={classNames(
              "h-full w-full items-center justify-center",
              "max-h-[24px] w-full shrink-0",
              isDark && "invert",
            )}
          />
        )}
        <MemAILogo
          className={classNames(
            "flex h-full w-full items-center justify-center",
            "max-h-[26px] w-full shrink-0 ",
            isDark && "invert",
            "scale-105",
          )}
        />
        <img
          src="/assets/customers/athena.png"
          alt="Athena Go"
          className={classNames(
            "flex max-h-[24px] shrink-0 scale-[70%] items-center justify-center",
            !isDark && "invert",
          )}
        />
      </div>
    </div>
  );
};
