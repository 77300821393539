export const MemAILogo = (props: React.ComponentProps<"svg">): JSX.Element => (
  <svg
    {...props}
    viewBox="0 0 160 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.0315 21.9556C61.5717 21.9556 66.0629 17.4705 66.0629 11.9377C66.0629 6.40505 61.5717 1.91992 56.0315 1.91992C50.4912 1.91992 46 6.40505 46 11.9377C46 17.4705 50.4912 21.9556 56.0315 21.9556ZM55.2686 12.271C55.5103 12.8114 56.7897 14.5587 58.481 14.5587C59.8796 14.5587 61.3451 13.669 62.0351 13.2501C62.1795 13.1624 62.29 13.0954 62.3587 13.0614C62.9886 12.7504 63.6319 13.2493 63.4421 13.9249C62.8887 15.8941 60.8464 19.6258 55.9148 19.6258C51.6033 19.6258 49.4841 16.3021 49.0406 15.1753C48.8882 14.8196 48.7895 14.4319 48.756 14.0223C48.7505 13.9542 48.7468 13.8866 48.7449 13.8192L48.7507 13.4847C48.8579 11.7747 50.2588 10.3666 51.9368 10.2299C53.3787 10.1124 54.686 10.9683 55.2686 12.271Z"
      fill="black"
    />
    <path
      d="M97.3643 11.5098L91.0701 13.5565C91.7435 14.7578 92.9549 15.1286 94.3364 14.6801C94.8237 14.5212 95.177 14.1643 95.5524 13.815C96.1977 13.1947 97.2997 12.8589 98.1659 13.1633C97.8751 14.7756 96.5459 16.2531 94.8678 16.799C92.1243 17.6913 89.3911 16.3963 88.4547 13.5116C87.5033 10.5669 89.0824 7.95747 91.7229 7.09795C94.3487 6.24405 97.0331 7.4472 97.9686 10.3282V10.3357C98.1218 10.8194 97.8481 11.3534 97.3643 11.5098ZM95.3691 10.0623C94.821 8.84508 93.3112 8.62787 92.2514 8.97335C91.2058 9.31324 90.2759 10.1681 90.4546 11.6605L95.3691 10.0623Z"
      fill="black"
    />
    <path
      d="M86.1384 10.8169V16.2838C86.1384 16.4797 86.0604 16.6676 85.9218 16.8061C85.783 16.9446 85.595 17.0224 85.3988 17.0224H83.7418V10.5787C83.7418 10.1516 83.5719 9.74207 83.2695 9.4401C82.9671 9.13811 82.5571 8.96847 82.1295 8.96847C81.7018 8.96847 81.2918 9.13811 80.9892 9.4401C80.6868 9.74207 80.5171 10.1516 80.5171 10.5787V16.2838C80.5171 16.4797 80.439 16.6676 80.3003 16.8061C80.1617 16.9446 79.9735 17.0224 79.7773 17.0224H78.0735V10.5787C78.0735 10.1519 77.9036 9.74254 77.6014 9.44073C77.2992 9.13894 76.8892 8.96937 76.462 8.96937C76.25 8.96901 76.04 9.01037 75.8443 9.09108C75.6484 9.17177 75.4703 9.29023 75.3204 9.43971C75.1702 9.58917 75.0513 9.76669 74.97 9.96213C74.8886 10.1576 74.8467 10.3671 74.8467 10.5787V16.2838C74.8467 16.4797 74.7688 16.6676 74.6302 16.8061C74.4913 16.9446 74.3033 17.0224 74.1072 17.0224H72.4531V10.8169C72.4531 9.75514 72.8754 8.73683 73.6272 7.98604C74.379 7.23525 75.3987 6.81348 76.462 6.81348C77.5713 6.81348 78.5709 7.4127 79.2957 8.13656C80.0215 7.4127 81.0236 6.81348 82.1304 6.81348C83.1934 6.81373 84.2127 7.23561 84.9646 7.98638C85.7161 8.73715 86.1384 9.7553 86.1384 10.8169Z"
      fill="black"
    />
    <path
      d="M113.939 10.8169V16.2838C113.939 16.4797 113.861 16.6676 113.723 16.8061C113.584 16.9446 113.396 17.0224 113.2 17.0224H111.543V10.5787C111.543 10.1516 111.373 9.74207 111.071 9.4401C110.768 9.13811 110.358 8.96847 109.93 8.96847C109.503 8.96847 109.093 9.13811 108.79 9.4401C108.488 9.74207 108.318 10.1516 108.318 10.5787V16.2838C108.318 16.4797 108.24 16.6676 108.101 16.8061C107.963 16.9446 107.774 17.0224 107.578 17.0224H105.874V10.5787C105.874 10.152 105.704 9.74275 105.402 9.441C105.1 9.13926 104.69 8.96962 104.263 8.96937C103.835 8.96937 103.426 9.13894 103.123 9.44073C102.821 9.74254 102.651 10.1519 102.651 10.5787V16.2838C102.651 16.4797 102.573 16.6676 102.435 16.8061C102.296 16.9446 102.108 17.0224 101.912 17.0224H100.254V10.8169C100.254 10.2911 100.357 9.7705 100.559 9.28471C100.76 8.79894 101.056 8.35755 101.428 7.98577C101.8 7.61401 102.242 7.31911 102.729 7.11796C103.215 6.91681 103.736 6.81334 104.263 6.81348C105.372 6.81348 106.372 7.4127 107.097 8.13656C107.822 7.4127 108.821 6.81348 109.93 6.81348C110.994 6.81373 112.013 7.23561 112.764 7.98638C113.516 8.73715 113.938 9.7553 113.938 10.8169H113.939Z"
      fill="black"
    />
  </svg>
);
